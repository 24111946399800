/*poppins font import stays here*/
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* declaring and using root variables in css*/
:root {
    --box-hover-shadow: 10px 8px 5px rgb(218, 218, 218);
    --color-fade: rgb(245, 245, 245);
    --color-light-fade: rgb(250, 250, 250);
    --color-background: #fff;
    --color-blue: #1652f0;
    --background-color:#fff;
    --fadeText:rgb(100,100,100);
    --headColor:rgb(50,50,50);
    --color-black:black;
    --color-red:brown;
    --color-fader: rgb(230, 230, 230);
}


::placeholder {
    font-size: 1.3rem;
    padding-left: 10px;
    text-align: left;
    width:100%;
    color:rgb(100,100,100);
    font-weight: lighter;
}

::-webkit-scrollbar {
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow:0 0 0 30px white inset !important
}



/*general styling for all element */
* {
    margin: 0;
    padding: 0;
    outline: 0;
    appearance: none;
    border: none;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

body {
    width: 100vw;
    height: 100vh;
    font-family: poppins, sans-serif;
    font-size: 0.88rem;
    background: var(--color-background);
    user-select: none;
    overflow-x: hidden;
    margin: 0;
    padding: 0;

}

.dashboardScreen {
    display: grid;
    height:100vh;
    width:100%;
    background: #fff;
    grid-template-columns: 20% 60% ;
    overflow: hidden;

}
.form_main{
    overflow-y: scroll;
    display:flex;
    flex-direction: column;
    align-items: center;
}


.boxunderline {
    width: 90%;
    height: 20px;
    border-bottom: 1px solid var(--color-fader);
    align-self: center;
    margin-bottom: 40px;
}

/*styling the drawer section*/
.drawerCon {
    display: none;
    padding-right:25px;
    padding-left:25px;
    background-color: rgb(240,240,240);
    width:100vw;
    height: 100vh;
    overflow-y: scroll;
    

    
}








@media screen and (max-width: 1200px) {
    .dashboardScreen{
      grid-template-columns: 30% 60% ;
      overflow-y: scroll;
    } 
  }
  
  @media screen and (max-width: 768px) {
    .dashboardScreen{
      grid-template-columns: 1fr;
    } 
  }

  @media screen and (max-width: 750px) {
    /*styling the drawer section*/
    .drawerCon{
        display: flex;
        left:-100vw;
        position:absolute;
        z-index: 150;
        width:0vh;
        background-color: var(--color-background);
        overflow-y: scroll;
        
    }

   


    .showdrawer{
        left:0vw;
        transition:1s ease;
        width:100vw;
        overflow: scroll;
    }

  }
 
  
    